


































































import { Component, Vue, Watch } from "vue-property-decorator";
import CityPane from "@/components/movie/city-pane.vue";
import ExportData from "@/components/export-data/index.vue";
import { RequestPaging } from "@/utils/util";
import { cityList } from "@/api/movie";
import { CinemaCityStatus } from "@/utils/type";

@Component({
    components: {
        CityPane,
        ExportData,
    },
})
export default class Goods extends Vue {
    queryObj: any = {
        name: "",
    };
    tableint: any = "3";
    supplierList: any[] = [];
    areaLists: any[] = [];

    tabCount = {
        priced: 0,
        unpriced: 0,
    };
    loading: Boolean = false;

    pager = new RequestPaging({ page: 1, size: 50 });
    activeName: any = "priced";
    cinemaActiveName: any = "1";
    apiGoodsLists = cityList;

    getList(page?: number, type?: number): void {
        this.loading = true;
        page && (this.pager.page = page);
        this.pager
            .request({
                callback: cityList,
                params: {
                    ...this.queryObj,
                },
            })
            .then((res: any) => {
                this.loading = false;
            });
    }

    handleReset() {
        this.queryObj = {
            name: "",
        };
        this.getList();
    }

    created() {
        this.getList();
        // this.getGoodsOtherList()
    }
}
